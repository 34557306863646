import React, { useState, useEffect } from "react";
import Navbar from "../Template/Navbar";
import axios from 'axios';
import { Link } from "react-router-dom";
import Login from "./Login";

const Request = () => {
    const uniqueid = localStorage.getItem('unique_id');
    const [data, setData] = useState();

    const getdata = () => {

        const myurl = "https://patilhardware.com/MobileWeb/userPreviousRequests";
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('uniqueid', uniqueid);
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            setData(response?.data)
        }).catch((error) => {
            console.log("Errors", error);

        })
    }
    useEffect(() => {
        getdata();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    if (uniqueid === null) {
        return <Login />
    }


    return (
        <>
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-md-3"></div>
                    <h1 className="request-heading">Request Status</h1>
                    {data?.post?.map((val, index) => {
                        return (
                            <div className="col-12 col-md-6" key={index}>

                                <div className="service-status-box">
                                    <div className="w-65">
                                        <p>Request No</p>
                                        <p>Service Name</p>
                                        <p>Date</p>
                                        {val.ratting <= 0 ? (
                                            <>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>

                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                            </>
                                        ) : val.ratting <= 1 ? (
                                            <>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>

                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                            </>
                                        ) : val.ratting <= 2 ? (
                                            <>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>

                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                            </>
                                        ) : val.ratting <= 3 ? (
                                            <>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>

                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                            </>
                                        ) : val.ratting <= 4 ? (
                                            <>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>

                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#b5bbc1" }}>
                                                    star
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>

                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                                <span className="material-symbols-outlined" style={{ color: "#F07C00" }}>
                                                    star
                                                </span>
                                            </>
                                        )
                                        }

                                    </div>
                                    <div className="w-35">
                                        <p>{val.request_no}</p>
                                        <p>{val.title}</p>
                                        <p>{val.post_date}</p>
                                        {val.requst_status === '0' ? <Link to='/service-status' className="btn btn-service" state={{ requestid: val?.request_no }}>Pending</Link> : <Link to='/service-request' className="btn btn-service">Completed</Link>}

                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    <div className="col-md-3"></div>
                </div>
            </div>
        </>
    )
}

export default Request