import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Splash from './components/pages/Splash';
import Notification from './components/pages/Notification';
import Service from './components/pages/Service';
import Request from './components/pages/Request';
import ServiceStatus from './components/pages/ServiceStatus';
import Term from './components/pages/Term';
import App from './App';
import './App.css';
import Otp from './components/pages/Otp';
import Login from './components/pages/Login';
import VerifyOtp from './components/pages/VerifyOtp';


const root = ReactDOM.createRoot(
    document.getElementById("root")
);
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route index path="/" element={<Splash />} />
                <Route index path="/term" element={<Term />} />
                <Route index path="/home" element={<Home />} />
                <Route index path="/otp" element={<Otp />} />
                <Route index path="/verify-otp" element={<VerifyOtp />} />
                <Route index path="/login" element={<Login />} />
                <Route index path="/service" element={<Service />} />
                <Route index path="/notification" element={<Notification />} />
                <Route index path="/service-request" element={<Request />} />
                <Route index path="/service-status" element={<ServiceStatus />} />
               


            </Route>
        </Routes>
    </BrowserRouter>
);

