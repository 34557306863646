import React, { useState, useRef } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const VerifyOtp = () => {
    const navigate = useNavigate();
    const mobile_no = localStorage.getItem('mobile_no');
    // const otp_no = localStorage.getItem('otp');
    const [otp1, setOtp1] = useState("");
    const [otp2, setOtp2] = useState("");
    const [otp3, setOtp3] = useState("");
    const [otp4, setOtp4] = useState("");

    const otpInputs = [useRef(), useRef(), useRef(), useRef()];

    const handleOtpChange = (index, value) => {
        if (value && index < otpInputs.length - 1) {
            otpInputs[index + 1].current.focus();
        }
    };
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const otp = otp1 + otp2 + otp3 + otp4;
    //     alert("OTP submitted: " + otp);
    // };

    const handleSubmitAddEvent = (e) => {
        e.preventDefault();
        const otp = otp1 + otp2 + otp3 + otp4;
        const myurl = "https://patilhardware.com/MobileWeb/userotpVerify";
        var bodyFormData = new URLSearchParams();

        bodyFormData.append('mobile_no', mobile_no);
        bodyFormData.append('otp', otp);



        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            if (response.data === '' || response.data.re === 'false') {
                window.alert('Please Enter Correct OTP')
            } else {
                localStorage.setItem('userid', response?.data?.data?.id)
                localStorage.setItem('unique_id', response?.data?.data?.user_unique_id)
                navigate('/service-request');
            }
        }).catch((error) => {
            console.log("Errors", error);

        })

    }
    return (
        <>
            <div className="container">
                <div className="verifyotp-page">
                    <div className="row w-100 d-flex justify-content-center">
                        <div className="col-lg-5 col-md-8 text-center">
                            <div className="login-card">
                                <div className="login-logo">
                                    <img src={require('../img/logo.png')} alt="" />
                                </div>
                                <div className="otp-box">
                                    <h1 className="text-center">OTP</h1>
                                    <form onSubmit={handleSubmitAddEvent} className="otp-form">
                                        <label>

                                            <input
                                                type="text"
                                                maxLength="1"
                                                value={otp1}
                                                onChange={(e) => {
                                                    setOtp1(e.target.value);
                                                    handleOtpChange(0, e.target.value);
                                                }}
                                                ref={otpInputs[0]}
                                                required
                                            />
                                        </label>
                                        <label>
                                            <input
                                                type="text"
                                                maxLength="1"
                                                value={otp2}
                                                onChange={(e) => {
                                                    setOtp2(e.target.value);
                                                    handleOtpChange(1, e.target.value);
                                                }}
                                                ref={otpInputs[1]}
                                                required
                                            />
                                        </label>
                                        <label>
                                            <input
                                                type="text"
                                                maxLength="1"
                                                value={otp3}
                                                onChange={(e) => {
                                                    setOtp3(e.target.value);
                                                    handleOtpChange(2, e.target.value);
                                                }}
                                                ref={otpInputs[2]}
                                                required
                                            />
                                        </label>
                                        <label>
                                            <input
                                                type="text"
                                                maxLength="1"
                                                value={otp4}
                                                onChange={(e) => {
                                                    setOtp4(e.target.value);
                                                    handleOtpChange(3, e.target.value);
                                                }}
                                                ref={otpInputs[3]}
                                                required
                                            />
                                        </label>
                                        <button type="submit" className="otp-submit-btn">Submit OTP</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyOtp