import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const uniqueid = localStorage.getItem('unique_id');
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const cleardata = () => {
    localStorage.clear();
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            Patil Hardware
          </Link>
          <button
            className={`d-lg-none d-md-none ${isMenuOpen ? "collapsed" : ""}`}
            type="button"
            style={{border:'none',background:"none",fontSize:'30px'}}
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            aria-controls="collapsibleNavbar"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="" style={{ color: "white" }}>
              {!isMenuOpen ? "☰" : "✕"}
            </span>
          </button>
          <div
            className={`collapse navbar-collapse ${
              isMenuOpen ? "show" : ""
            }`}
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/home">
                  <img className="nav-img" src={require('../img/hom.png')} alt="" />
                  <span>Home</span> 
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/service-request">
                  <img className="nav-img" src={require('../img/req.png')} alt="" />
                  <span>Request Status</span>
                </Link>
              </li>
              <li className="nav-item">
                {uniqueid? <Link className="nav-link" onClick={cleardata} to="/">
                  <img className="nav-img" src={require('../img/lgin.png')} alt="" />
                  <span>Logout</span></Link>: <Link className="nav-link" to="/login">
                  <img className="nav-img" src={require('../img/log-out.png')} alt="" />
                  <span>Login</span></Link>}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
