import React, { useState } from "react";
import axios from 'axios';
import Navbar from "../Template/Navbar";
import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

const Service = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [verified, setverified] = useState(true);
    const { id } = location.state;
    const [formData, setFormData] = useState({
        username: '',
        serviceid: '',
        mobileno: '',
        address: '',
        landmark: '',
        post_date: '',
        start_time: '',
        end_time: '',
        unique_id: '',
    });
    const handleInput = (e) => {
        const basicData = { ...formData };
        basicData[e.target.name] = e.target.value;
        setFormData(basicData);
    }


    const handleSubmitAddEvent = (e) => {

        e.preventDefault();
        const { username, mobileno, address, landmark, post_date, start_time, end_time } = formData
        if (!username || !mobileno || !address || !landmark || !post_date || !start_time || !end_time) {
            alert('All required field');
            return;
        }
        setverified(false)
        const myurl = "https://patilhardware.com/MobileWeb/serviceRequestNew";
        var bodyFormData = new URLSearchParams();

        bodyFormData.append('username', formData.username);
        bodyFormData.append('serviceid', id);
        bodyFormData.append('mobileno', formData.mobileno);
        bodyFormData.append('address', formData.address);
        bodyFormData.append('landmark', formData.landmark);
        bodyFormData.append('post_date', formData.post_date);
        bodyFormData.append('start_time', formData.start_time);
        bodyFormData.append('end_time', formData.end_time);
        bodyFormData.append('unique_id', '');
        bodyFormData.append('gcm_id', "cu4BMzQEqLo:APA91bENusFSumQlKyzy_pmxOybtNk2XvWS4rRodpTv1X4E3Fx3Wo1YCiF-iSUQqnLaiTWUtaWqfELX_os0CuaOSJ2TDRHMYXWevr-0y9HohF86pSEIchGBl5Y9I7HNATnNsjp3eks5S");

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            alert("otp send on your whatsapp")
            navigate('/otp');
            setverified(false)
            localStorage.setItem('userid', response?.data?.userid)
            localStorage.setItem('unique_id', response?.data?.uniqueid)
        }).catch((error) => {
            console.log("Errors", error);
        })

    }



    return (
        <>
            <Navbar />
            <div className="container">
                <div className="request-form-page">
                    <div className="row w-100 d-flex justify-content-center">
                        <div className="col-md-6 col-12 form">
                            <h1 className="request-heading">Service Request Form</h1>
                            <form onSubmit={(e) => handleSubmitAddEvent(e)}>
                                <div className="mb-3 mt-3">
                                    <label htmlFor="email" className="form-label">Username</label>
                                    <input type="email" className="form-control" id="email" placeholder="Enter your username" name="username" onChange={handleInput} value={formData.username} required />
                                </div>
                                <div className="mb-3 mt-3">
                                    <label htmlFor="email" className="form-label">Mobile Number</label>
                                    <input type="email" className="form-control" id="email" placeholder="Enter your mobile number" name="mobileno" onChange={handleInput} value={formData.mobileno} required />
                                </div>
                                <div className="mb-3 mt-3">
                                    <label htmlFor="email" className="form-label">Please Enter date</label>
                                    <input type="date" className="form-control" id="email" name="post_date" onChange={handleInput} value={formData.post_date} required />
                                </div>
                                <div className="mb-3 mt-3">
                                    <label htmlFor="email" className="form-label">Preferred Time</label>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ display: "block" }}>
                                            <label htmlFor="email" className="form-label">Start Time</label>
                                            <input  style={{ width: "145px" }} type="time" className="form-control m-1" id="email" placeholder="Start Time" name="start_time" onChange={handleInput} value={formData.start_time} required />
                                        </div>
                                        <div style={{ display: "block" }}>
                                            <label htmlFor="email" className="form-label">End Time</label>
                                            <input  style={{ width: "145px" }}  type="time" className="form-control m-1" id="email" placeholder="End Date" name="end_time" onChange={handleInput} value={formData.end_time} required />
                                        </div>
                                    </div>

                                    <div className="mb-3 mt-3">
                                        <label htmlFor="email" className="form-label">Address</label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter address" name="address" onChange={handleInput} value={formData.address} required />
                                    </div>
                                    <div className="mb-3 mt-3">
                                        <label htmlFor="email" className="form-label">Landmark</label>
                                        <input type="email" className="form-control" id="email" placeholder="Enter Landmark" name="landmark" onChange={handleInput} value={formData.landmark} required />
                                    </div>
                                </div>
                                <button type="submit" disabled={!verified} className="btn term-btn" onClick={(e) => handleSubmitAddEvent(e)}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Service