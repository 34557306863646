import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Splash = () => {

    const navigate = useNavigate();
    useEffect(() => {
        const redirectTimeout = setTimeout(() => {
            navigate('/term');
        }, 2000);
        return () => {
            clearTimeout(redirectTimeout);
        };
    }, [navigate]);

    return (
        <>
            <div className="container-fluid home">
                <div className="row">
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-4 ">
                        <Link to='/term'> <img className="logo-img mb-4" src={require("../img/logo.png")} alt="sgdg" /></Link>

                        <h1 className="logo-text text-center">Patil Hardware</h1>
                    </div>
                    <div className="col-md-4">

                    </div>
                </div>
            </div>
        </>
    )
}

export default Splash