import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


const Term = () => {
    const [data, setData] = useState();
    const getdata = () => {
        fetch('https://patilhardware.com/MobileWeb/terms_condition')
            .then(response => {
                return response.json()
            }).then(
                data => {
                    return setData(data)
                }
            )
    }
    useEffect(() => {
        getdata();
    }, [])

    return (
        <>
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-12">
                        <div className="term-text">
                            <h1 className="text-center">Term & Conditions</h1>
                        </div>
                        <div className="mt-3">
                            <ul className="term-list" dangerouslySetInnerHTML={{ __html: data?.post }}></ul>
                        </div>
                        <Link to='/home' className="btn term-btn">Continue</Link>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Term