import React, { useState, useEffect } from "react";
import Navbar from "../Template/Navbar";
import axios from 'axios';


const Notification = () => {
    const userid = sessionStorage.getItem('userid')
    const [data, setData] = useState();
    const getdata = () => {

        const myurl = "https://patilhardware.com/MobileWeb/userNotification";
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('user_unqueId', userid);
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            setData(response?.data)
        }).catch((error) => {
            console.log("Errors", error);

        })
    }
    useEffect(() => {
        getdata();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        <>
            <Navbar />
            <div className="container">
                <div className="row">
                    <h1 className="request-heading">Notification</h1>
                    <div className="col-md-3"></div>
                    <div className="col-md-6 col-12">
                        {data?.re === 'false' ? <p className="text-center" style={{fontSize:"24px"}}>opps!</p> :
                            data?.map((val) => {
                                return (
                                    <div className="notification-box">
                                        <div><img src={require('../img/img.png')} alt="xuyz" /></div>
                                        <div> <p >Lorem Ipsum is simply dummy text of the printing and typesetting </p></div>
                                    </div>
                                )
                            })}

                        {/* {data?.map((val) => {
                            return (
                                <div className="notification-box">
                                    <div><img src={require('../img/img.png')} alt="xuyz" /></div>
                                    <div> <p >Lorem Ipsum is simply dummy text of the printing and typesetting </p></div>
                                </div>
                            )
                        })} */}
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        </>
    )
}


export default Notification