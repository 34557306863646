import React, { useState } from 'react';
import Navbar from "../Template/Navbar";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
const Rating = ({ value, onClick }) => {
    const [hoverValue, setHoverValue] = useState(0);





    const handleMouseEnter = (newValue) => {
        setHoverValue(newValue);
    };

    const handleMouseLeave = () => {
        setHoverValue(0);
    };

    const handleClick = (newValue) => {
        onClick(newValue);
    };

    const stars = [];
    const maxValue = Math.max(value, hoverValue);

    for (let i = 1; i <= 5; i++) {
        const starClass =
            i <= maxValue ? 'star selected' : hoverValue >= i ? 'star hover' : 'star';

        stars.push(
            <span
                key={i}
                className={starClass}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(i)}
            >
                ★
            </span>
        );
    }

    return <div className="rating">{stars}</div>;
};


const ServiceStatus = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { requestid } = location.state;
    const uniqueid = localStorage.getItem('unique_id');
    const userid = localStorage.getItem('userid');
    const [rating, setRating] = useState(0);

    const handleRatingChange = (newValue) => {
        setRating(newValue);
    };
    const handleSubmitAddEvent = (e) => {

        e.preventDefault();
        const myurl = "https://patilhardware.com/MobileWeb/userRating";
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('userid', userid);
        bodyFormData.append('requestid', requestid);
        bodyFormData.append('ratting', rating);
        bodyFormData.append('user_uniqueid', uniqueid);
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            console.log(response)
            navigate('/service-request');
        }).catch((error) => {
            console.log("Errors", error);
        })
    }

    return (
        <>
            <Navbar />
            <div className="container ">
                <div className="service-status-page">
                    <div className="row w-100 d-flex justify-content-center">
                        <div className="col-md-5 text-center">
                            <Link to='/term'> <img className="starus-img " src={require("../img/star.jpg")} alt="sgdg" /></Link>
                            <div className="text-center mt-5 star">
                                <h1 className="request-heading">Rate Your Service</h1>
                                <Rating value={rating} onClick={handleRatingChange} />
                                {/* <p>Current rating: {rating}</p> */}
                            </div>
                            <button type="submit" className="btn starus-btn " onClick={handleSubmitAddEvent}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
};

export default ServiceStatus