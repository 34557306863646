import React, { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const [verified, setverified] = useState(true);

    const [formData, setFormData] = useState({ mobile_no: '', });

    const handleInput = (e) => {
        const basicData = { ...formData };
        basicData[e.target.name] = e.target.value;
        setFormData(basicData);
    }


    const handleSubmitAddEvent = (e) => {
        e.preventDefault();
        setverified(false)
        const myurl = "https://patilhardware.com/MobileWeb/userLogin";
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('mobile_no', formData.mobile_no);
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            if (response?.data?.status === 'true') {
                alert('otp send on your whatsapp');
                localStorage.setItem('mobile_no', formData.mobile_no)
                localStorage.setItem('otp', response?.data?.otp)
                navigate('/verify-otp');
            } else {
                alert(response?.data?.msg);
                navigate('/home');
            }
        }).catch((error) => {
            console.log("Errors", error);
        })
    }

    return (
        <>
         <div className="container">
                <div className="login-page">
                    <div className="row w-100 d-flex justify-content-center">
                        <div className="col-md-5 text-center">
                            <div className="login-card">
                                <div className="login-logo">
                                    <img src={require('../img/logo.png')} alt="" />
                                </div>
                                <div className="log-box">
                                    <h1 className="text-center mb-4">Login </h1>
                                    <form onSubmit={handleSubmitAddEvent} className="login-form">
                                        <input type="text" class="form-control text-left" id="no" name="mobile_no" onChange={handleInput} value={formData.mobile_no} min="10" max="10" required placeholder="Pleace enter your Number" />
                                        <button type="submit" disabled={!verified} className="login-submit-btn">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default Login