import React, { useState, useEffect } from "react";
import Navbar from "../Template/Navbar";
import { Link } from "react-router-dom";


const Home = () => {
    const [data, setData] = useState();

    const getdata = () => {
        fetch('https://patilhardware.com/MobileWeb/service')
            .then(response => {
                return response.json()
            }).then(
                data => {
                    return setData(data)
                }
            )
    }
    useEffect(() => {
        getdata();
    }, [])


    return (
        <>
            <Navbar />
            <div className="container ">
                <div className="home-page">
                    <div className="row">

                        {data?.post?.map((val, index) => {
                            return (
                                <div className="col-md-3 col-6 " key={index}>
                                    <Link to='/service' state={{ id: val.id }} className="home-img-box">
                                        <img src={`http://patilhardware.com/adminassets/uplodes/service/${val.s_image}`} alt={val.s_image} />
                                        <p className="text-center">{val.title}</p>
                                    </Link>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Home